import { useQuery } from "react-query";
import ITProjectsAPI from "../ITProjectsAPI";

export const useApprovals = (config = {}) => {
  const queryFn = async () => {
    return (
      (
        await ITProjectsAPI.getApprovals()
      ).data?.Done?.RequestResponseItems?.filter(
        (el) => el.RequestStatus === "Started"
      )?.sort((a, b) => new Date(b.RequestDate) - new Date(a.RequestDate)) ?? []
    );
  };

  return useQuery("it-projects-approvals", queryFn, {
    staleTime: 1000 * 5,
    retry: 1,
    ...config,
  });
};

export const useRequestApprovals = (config = {}) => {
  const queryFn = async () => {
    return (await ITProjectsAPI.getRequestApprovals()).data;
  };

  return useQuery("it-projects-request-approvals", queryFn, {
    staleTime: 1000 * 5,
    retry: 1,
    ...config,
  });
};

export const useApprovalDetails = ({ args, ...config }) => {
  const queryFn = async () => {
    const basicDetails = (await ITProjectsAPI.getRequest(args)).data?.Done
      ?.PNRequestDetails;
    const advancedDetails = (
      await ITProjectsAPI.getRequestDetails({ id: args?.params?.requesterId })
    ).data;

    return { ...basicDetails, advancedDetails: { ...advancedDetails } };
  };

  return useQuery(["it-projects-approval-details", args], queryFn, {
    staleTime: 1000 * 5,
    ...config,
    enabled: !!args.params,
  });
};

export const useRequestApprovalDetails = ({ args, ...config }) => {
  const queryFn = async () => {
    const requestDetails = (
      await ITProjectsAPI.getRequestDetails({ id: args?.params?.RequestID })
    ).data;
    const requestedTimeline = (
      await ITProjectsAPI.getRequestedTimeline({ id: args?.params?.RequestID })
    ).data;
    return {
      requestDetails: { ...requestDetails },
      requestedTimeline: { ...requestedTimeline },
    };
  };

  return useQuery(["it-projects-request-approval-details", args], queryFn, {
    staleTime: 1000 * 5,
    ...config,
    cacheTime: 100,
  });
};

export const useScoreCard = ({ args, ...config }) => {
  const queryFn = async () => {
    const scoreCardAnswers = await ITProjectsAPI.getScoreCardAnswers(
      args.params
    );
    const scoreCardOptions = await ITProjectsAPI.getScoreCardOptions({
      requestId: scoreCardAnswers?.data?.requestId,
    });

    return {
      scoreCardAnswers: scoreCardAnswers,
      scoreCardOptions: scoreCardOptions,
    };
  };

  return useQuery("score-card", queryFn, {
    staleTime: 1000 * 5,
    ...config,
    enabled: !!args?.params?.requestId,
    cacheTime: 100,
  });
};

export const useGetBusinessImpactEnums = ({ ...config }) => {
  const queryFn = async () => {
    return await ITProjectsAPI.getBusinessImpactEnums();
  };

  return useQuery(["business-impact-enums"], queryFn, {
    staleTime: Infinity,
    ...config,
  });
};

export const useMe = ({ ...config }) => {
  return useQuery("me", ITProjectsAPI.getMe, {
    staleTime: 1000 * 60 * 60,
    ...config,
  });
};

export const useCheckListOfUsers = ({ args, ...config } = {}) => {
  const queryFn = async () => {
    return await args.map((arg) => {
      if (!arg) return;
      const data = { id: arg?.reviewerId };
      return ITProjectsAPI.getTeamMember(data);
    });
  };
  return useQuery(["list-of-users", args], queryFn, {
    staleTime: 1000 * 60 * 60,
    ...config,
    enabled: args?.length > 0 && !!args[0]?.reviewerId,
  });
};

export const useApprovalsList = ({ teamMemberNumber, approvalLevel }) => {
  const queryFn = async () => {
    return await ITProjectsAPI.generateApprovalsList(
      teamMemberNumber,
      approvalLevel
    );
  };
  return useQuery(["approvals-list", teamMemberNumber], queryFn, {
    staleTime: 1000 * 60,
    enabled:
      typeof teamMemberNumber === "number" &&
      !Number.isNaN(teamMemberNumber) &&
      typeof approvalLevel === "string",
  });
};

export const useGetFilesAuth = ({ ...config }) => {
  const queryFn = async () => {
    return await ITProjectsAPI.getFilesAuth();
  };
  return useQuery("get-files-auth", queryFn, {
    staleTime: 1000 * 60 * 60,
    ...config,
  });
};

export const useGetFiles = ({ path, token, ...config }) => {
  return useQuery(
    ["get-files"],
    () => ITProjectsAPI.getFiles({ path, token }),
    {
      staleTime: 1000 * 60,
      ...config,
      refetchOnWindowFocus: true,
      enabled: path !== null && !!token,
      cacheTime: 60,
    }
  );
};
