import React, { useState, useMemo, useEffect } from "react";
import { queryCache, useQuery } from "react-query";
import { useAsync } from "@applied-med/ui/dist/hooks";
import { get, getForNotifications } from "_shared/request";
import { user, JwtToken, msalJwtToken } from "_shared/auth";
import GDRAPI from "./GenericDocumentRouting/GDRAPI";
import ITProjectsAPI from "./ITProjects/ITProjectsAPI";
import PORAPI from "./POR/PORAPI";
// import * as FORMSAPI from "./Forms/FormAPI/Form.queries";
import useViewport from "_shared/useViewport";
import { graphql } from "_shared/request";
import { useFeature } from "_shared/feature";
import { getEnv } from "../../utils/constants";
import { findIndex, propEq, isEmpty, pathOr } from "ramda";

const getPendingActions = async () => {
  try {
    const url = `${getEnv("DECISIONS2_ENDPOINT")}/`;
    const { data } = await get({
      url,
      params: {
        FlowAlias: "PendingActionsAPI_GetPendingActions",
        sessionid: JwtToken,
        ForceRefresh: "False",
        TeamMember: user.TeamId,
        outputtype: "RawJson",
        action: "api",
      },
    });

    return data;
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const useGetLMSELearningPendings = () => {

  const queryFn = async () => {
    try {
      const { data } = await get({url: `${getEnv("LMS_NONQS_URL")}/Team/PendingAssignments/TeamMembers/${user?.TeamId}`});
      return data;
    } catch (error) {
      return {TotalPendingTrainings: 0};
    }
  };

  return useQuery(['LMSELearningPending', user.TeamId], queryFn, {
  enabled: !!user?.TeamId,
  staleTime: 1000 * 60,
  refetchOnMount: true,
  });
}

export const useGetLMSSOPPendings = () => {
  const queryFn = async () => {
    try {
      const { data } = await get({url: `${getEnv("LMS_ROOT_URL")}/TeamMember/${user?.TeamId}?filter=Pending`});
      return data
    } catch (error) {
      return {Assignments: []};
    }
  }

  return useQuery(['LMSSOPPending', user.TeamId], queryFn, {
  enabled: !!user?.TeamId,
  staleTime: 1000 * 60,
  refetchOnMount: true,

  });
}

export const useGeteSORPendings = () => {
  const queryFn = async () => {
    try {
      const { data } = await get({url: `${getEnv("BASEURL")}/esor/totalpendingactions/${user?.TeamId}`});
      return data
    } catch (error) {
      return {Done: {TotalPending: 0}};
    }
  }

  return useQuery(['eSORPending', user.TeamId], queryFn, {
  enabled: !!user?.TeamId,
  staleTime: 1000 * 60,
  refetchOnMount: true,

  });
}

const usePendingActions = (config = {}) => {
  const queryFn = async () => {
    const result = await getPendingActions();
    if (!result) {
      return { Items: [], TotalPending: 0 }; // return default values if result is undefined
    }
    const { Items, TotalPending } = result;
    const cleanedItems = Array.isArray(Items)
      ? Items.filter((el) => el.Name !== "Purchase Order Requests").map(
          (el) => ({
            ...el,
            Name: el.Name,
          })
        )
      : [];
    return { Items: cleanedItems, TotalPending };
  };

  return useQuery("pending-actions", queryFn, {
    staleTime: 1000 * 5,
    ...config,
  });
};

const DEFAULT_ITEMS = {
  ["Purchase Order Requests"]: {
    Name: "Purchase Order Requests",
    Url: "/notifications/por/approvals",
    Total: 0,
    hasAccess: true,
  },
  ["General Document Routing"]: {
    Name: "General Document Routing",
    Url: "/notifications/generic-document-routing",
    Total: 0,
    hasAccess: true,
  },
  ["Request Approvals"]: {
    Name: "Request Approvals",
    Url: "/notifications/request-portal",
    Total: 0,
    hasAccess: true,
  },
  ["Product Complaint Form"]: {
    Name: "Product Complaint Form",
    Url: "/notifications/forms/PCF",
    Total: 0,
    hasAccess: true,
  },
  ["Learning Management System"]: {
    Name: "Learning Management System",
    Url: "https://learning.appliedmedical.com/",
    isSAP: false,
    Total: 0,
    HasTotal: true,
  },
  ["eSOR"]: {
    Name: "eSOR - Electronic Shop Order Request",
    Url: "https://decisionsprd2sa.appliedmed.com/Primary/H/?FolderId=750eff99-88fd-11ec-916f-005056bf3f1b&pageName=eSOR%20Home%20Page%20v2&Chrome=Off",
    isSAP: false,
    Total: 0,
    HasTotal: true,
  }
  // ["IQ DMS - Workflow Inbox"]: {
  //   Name: "IQ DMS - Workflow Inbox",
  //   Url: "https://gw.appliedmed.com/zlpd/shells/abap/FioriLaunchpad.html#WorkflowTask-displayInbox?allItems=true",
  //   Total: 0,
  //   hasAccess: true,
  // },
};

const defaultNotificationConfig = {
  retry: false,
  staleTime: 1000 * 60,
  refetchOnMount: true,
  refetchInterval: 1000 * 60,
  // refetchIntervalInBackground: true,
};

export const useNotifications = ( config = {}) => {
  const viewport = useViewport();

  const eLearningData = useGetLMSELearningPendings();
  const sopData = useGetLMSSOPPendings();
  const eSORData = useGeteSORPendings();
  
  const [items, setItems] = useState(() => {
    const { Items: pendingActions = [] } =
      queryCache.getQueryData("pending-actions") || {};
    const PORApprovals = queryCache.getQueryData("por-approvals")?.items || [];
    const GDRApprovals = queryCache.getQueryData("gdr-approvals") || [];
    const ITApprovals =
      queryCache.getQueryData("it-projects-request-approvals")?.assignments ||
      [];
    console.log("ITApprovals", ITApprovals);
    // const PCFApprovals = queryCache.getQueryData("pcf-approvals") || [];
    // console.log({ pendingActions });
    const eSOR = queryCache.getQueryData(["eSORPending", user?.TeamId]);

    const eLearning = queryCache.getQueryData(["LMSELearningPending", user?.TeamId]);
    const SOP = queryCache.getQueryData(["LMSSOPPending", user?.TeamId]);

    const LMSTotalPending = (eLearning?.TotalPendingTrainings || 0) + (SOP?.Assignments?.length || 0)

    return pendingActions.reduce((acc, el) => ({ ...acc, [el.Name]: el }), {
      ["Purchase Order Requests"]: {
        ...DEFAULT_ITEMS["Purchase Order Requests"],
        Total: PORApprovals.length,
        // hasAccess:
        //   !queryCache.getQuery("por-approvals") ||
        //   queryCache.getQuery("por-approvals")?.state.failureCount < 1,
      },
      ["General Document Routing"]: {
        ...DEFAULT_ITEMS["General Document Routing"],
        Total: GDRApprovals.length,
        hasAccess:
          !queryCache.getQuery("gdr-approvals") ||
          queryCache.getQuery("gdr-approvals")?.state.failureCount < 1,
      },
      ["Request Approvals"]: {
        ...DEFAULT_ITEMS["Request Approvals"],
        Total: ITApprovals.length,
        hasAccess:
          !queryCache.getQuery("it-projects-request-approvals") ||
          queryCache.getQuery("it-projects-request-approvals")?.state
            .failureCount < 1,
      },
      ["Product Complaint Form"]: {
        ...DEFAULT_ITEMS["Product Complaint Form"],
        Total: 0,
        // hasAccess: !queryCache.getQuery("pcf-approvals")?.state.failure < 100,
        hasAccess: true,
      },
      ["Learning Management System"]: {
        ...DEFAULT_ITEMS["Learning Management System"],
        Total: LMSTotalPending,
      },
      ["eSOR"]: {
        ...DEFAULT_ITEMS["eSOR"],
        Total: eSOR?.Done?.TotalPending || 0,
      }
    });
  });

  const isPORApprovalsQueryEnabled =
    !!msalJwtToken &&
    items["Purchase Order Requests"].hasAccess &&
    (!queryCache.getQuery("por-approvals") ||
      queryCache.getQuery("por-approvals")?.state?.failureCount < 2);

  const PORApprovalsQuery = PORAPI.useApprovals({
    ...config,
    ...defaultNotificationConfig,
    enabled: isPORApprovalsQueryEnabled,
    refetchOnMount: isPORApprovalsQueryEnabled,
    refetchOnWindowFocus: isPORApprovalsQueryEnabled,
    staleTime: 1000 * 30,
    refetchInterval: 1000 * 30,
    retry: 3,

    onSuccess: (data) => {
      setItems((prevState) => {
        const POR = prevState["Purchase Order Requests"];
        // console.log({POR});
        return {
          ...prevState,
          [POR.Name]: {
            ...POR,
            Total: data.items.length,
            Url: "/notifications/por/approvals",
          },
        };
      });
    },
    onError: () => {
      setItems((prevState) => {
        const item = prevState["Purchase Order Requests"];

        return {
          ...prevState,
          [item.Name]: { ...item, Total: 0, hasAccess: false },
        };
      });
    },
  });

  const isGDRApprovalsQueryEnabled =
    !!JwtToken &&
    user.TeamId &&
    items["General Document Routing"].hasAccess &&
    (!queryCache.getQuery("it-projects-approvals") ||
      queryCache.getQuery("it-projects-approvals")?.state.failureCount < 1);

  const GDRApprovalsQuery = GDRAPI.useApprovals({
    ...config,
    ...defaultNotificationConfig,
    enabled: isGDRApprovalsQueryEnabled,
    refetchOnMount: isGDRApprovalsQueryEnabled,
    refetchOnWindowFocus: isGDRApprovalsQueryEnabled,
    onSuccess: (data) => {
      setItems((prevState) => {
        const item = prevState["General Document Routing"];

        return { ...prevState, [item.Name]: { ...item, Total: data.length } };
      });
    },
    onError: () => {
      setItems((prevState) => {
        const item = prevState["General Document Routing"];

        return {
          ...prevState,
          [item.Name]: { ...item, Total: 0, hasAccess: false },
        };
      });
    },
  });

  const isITRequestApprovalsQueryEnabled =
    !!JwtToken &&
    user.TeamId &&
    items["Request Approvals"].hasAccess &&
    (!queryCache.getQuery("it-projects-request-approvals") ||
      queryCache.getQuery("it-projects-request-approvals")?.state.failureCount <
        1);

  const ITRequestApprovalsQuery = ITProjectsAPI.useRequestApprovals({
    ...config,
    ...defaultNotificationConfig,
    enabled: isITRequestApprovalsQueryEnabled,
    refetchOnMount: isITRequestApprovalsQueryEnabled,
    refetchOnWindowFocus: isITRequestApprovalsQueryEnabled,
    onSuccess: (data) => {
      setItems((prevState) => {
        const item = prevState["Request Approvals"];

        return {
          ...prevState,
          [item.Name]: {
            ...item,
            Total: data?.assignments ? data.assignments.length : 0,
          },
        };
      });
    },
    onError: () => {
      setItems((prevState) => {
        const item = prevState["Request Approvals"];

        return {
          ...prevState,
          [item.Name]: { ...item, Total: 0, hasAccess: false },
        };
      });
    },
  });

  const pendingActionsQuery = usePendingActions({
    ...config,
    ...defaultNotificationConfig,
    enabled: !!JwtToken && user.TeamId,
    onSuccess: ({ Items }) => {
      setItems((prevState) => {
        let newState = Items.reduce(
          (acc, el) => ({ ...acc, [el.Name]: el }),
          prevState
        );
        // newState["Purchase Order Requests"]["Url"] =
        //   DEFAULT_ITEMS["Purchase Order Requests"].Url;
        // newState["Purchase Order Requests"]["IsSAP"] = false;
        return newState;
      });
    },
  });

  const notificationsState = useAsync({
    queries: {
      PORApprovalsQuery,
      // GDRApprovalsQuery,
      // ITRequestApprovalsQuery,
      pendingActionsQuery,
    },
  });

  // useEffect(() => {
  //   console.log({ notificationsState });
  // }, [notificationsState]);

  useEffect(() => {

    setItems((prevItems) => ({
      ...prevItems,
      ["eSOR"]: {
        ...prevItems["eSOR"],
        Total: eSORData?.data?.Done?.TotalPending,
      },
    }));
  }, [eSORData.data])

  useEffect(() => {
    const total  = (eLearningData?.data?.TotalPendingTrainings || 0) + (sopData?.data?.Assignments?.length || 0)

    setItems((prevItems) => ({
      ...prevItems,
      ["Learning Management System"]: {
        ...prevItems["Learning Management System"],
        Total: total,
      },
    }));
  }, [eLearningData.data, sopData.data]);

  useEffect(() => {
    if (items["Purchase Order Requests"]) {
      const POR = items["Purchase Order Requests"];

      setItems((prevState) => ({
        ...prevState,
        [POR.Name]: {
          ...POR,
          // Url: !viewport.isMobile
          //   ? //  PORApprovalsQuery?.data?.desktopLink ?? "zpor:zpor?env=ZGW_PRD"
          //     "zpor:zpor?env=ZGW_PRD"
          //   : "/notifications/por/approvals",
          Url: "/notifications/por/approvals",
        },
      }));
    }
  }, [viewport.isMobile, PORApprovalsQuery.data]);

  // const iqdms = {
  //   ...DEFAULT_ITEMS["IQ DMS - Workflow Inbox"],
  //   Total: 0,
  //   // hasAccess: !queryCache.getQuery("pcf-approvals")?.state.failure < 100,
  //   hasAccess: true,
  // };

  const sortedItems = useMemo(
    () => Object.values(items).sort((a, b) => b.Total - a.Total),
    [items]
  );

  // if (
  //   findIndex(propEq("Name", "IQ DMS - Workflow Inbox"))(sortedItems) === -1
  // ) {
  //   const index = findIndex(
  //     propEq("Name", "PLM - Product Lifecycle Management ")
  //   )(sortedItems);
  //   sortedItems.splice(index + 1, 0, iqdms);
  // }

  const totalPending = useMemo(
    () =>
      Object.values(items).reduce(
        (acc, { Total }) => (Total ? acc + Total : acc),
        0
      ),
    [items]
  );
  // console.log({
  //   sortedItems,
  // });

  return { data: { items: sortedItems, totalPending }, ...notificationsState };
};
