import { getEnv } from "../../../../utils/constants";
import { pathOr } from "ramda";
import { JwtToken, user } from "../../../../_shared/auth";
import { post, get, put, patch, del } from "../../../../_shared/request";
import axios from "axios";

const DEFAULT_PARAMS = {
  action: "api",
  outputType: "JSON",
};

export const getApprovals = () => {
  return get({
    url: `${getEnv("DECISIONS2_ENDPOINT")}/Primary/`,
    headers: {
      Authorization: `Session ${JwtToken}`,
    },
    params: {
      ...DEFAULT_PARAMS,
      FlowAlias: "ResponseItemsPNC",
      RequesterTeamID: user.TeamId,
    },
  });
};

// export const getRequestApprovals = () => {
//   return get({
//     url: `${getEnv(
//       "DECISIONS2_ENDPOINT"
//     )}/Primary/?FlowAlias=PendingResponseItemsARC`,
//     headers: {
//       Authorization: `Session ${JwtToken}`,
//     },
//     params: {
//       ...DEFAULT_PARAMS,
//       outputtype: "JSON",
//       RequesterTeamID: user.TeamId,
//       action: "api",
//     },
//   });
// };

export const getRequestApprovals = () => {
  return get({
    url: `${getEnv("ITROOTURL")}/myapplied/assignments?TeamMemberId=${
      user.TeamId
    }`,
  });
};

export const fetchRequestList = async (_, { TeamId }) => {
  const { data } = await get({
    url: `${getEnv("DECISIONS2_ENDPOINT")}/Primary/`,
    headers: {
      Authorization: `Session ${JwtToken}`,
    },
    params: {
      ...DEFAULT_PARAMS,
      FlowAlias: "ResponseItemsPNC",
      RequesterTeamID: TeamId,
    },
  });

  const list = pathOr([], ["Done", "RequestResponseItems"], data);
  return list
    .filter((el) => el.RequestStatus === "Started")
    .sort((a, b) => new Date(b.RequestDate) - new Date(a.RequestDate));
};

export const getRequest = async ({ params }) => {
  return get({
    url: `${getEnv("DECISIONS2_ENDPOINT")}/Primary/`,
    headers: {
      Authorization: `Session ${JwtToken}`,
    },
    params: {
      ...DEFAULT_PARAMS,
      ...params,
      flowAlias: "RequestDetailsPNC",
      teamId: user.TeamId,
    },
  });
};

export const getRequestDetails = ({ id }) => {
  return get({
    url: `${getEnv("ITROOTURL")}/request-management/requests/${id}/detail`,
  });
};

export const getRequestStatus = ({ id }) => {
  return get({
    url: `${getEnv("ITROOTURL")}/request-status/${id}`,
  });
};

export const getRequestedTimeline = ({ id }) => {
  return get({
    url: `${getEnv(
      "ITROOTURL"
    )}/request-management/requests/${id}/requested-timeline`,
  });
};

export const getRequestApproverDetails = ({ params }) => {
  return get({
    url: `${getEnv(
      "DECISIONS2_ENDPOINT"
    )}/Primary/?FlowAlias=RequestDetailsARC&outputtype=JSON&action=api&RequestID=${
      params.RequestID
    }&TeamID=${params.TeamID}&sessionID=${JwtToken}`,
  });
};

export const sendResponse = async (data) => {
  const response = await post({
    url: `${getEnv(
      "DECISIONS2_ENDPOINT"
    )}/Primary/REST/Assignment/ProcessFormAssignmentResponse`,
    params: { SessionId: JwtToken },
    data,
  });

  return response;
};

export const getScoreCardOptions = ({ requestId }) => {
  return get({
    url: `${getEnv("ITROOTURL")}/scoring/score-card-type/${requestId}`,
  });
};

export const getScoreCardAnswers = ({ requestId }) => {
  return get({ url: `${getEnv("ITROOTURL")}/scoring/${requestId}` });
};

export const updateScoring = async ({ requestId, data }) => {
  return await put({
    url: `${getEnv("ITROOTURL")}/scoring/${requestId}`,
    data,
  });
};

export const updateRequestStatus = async ({ data }) => {
  return await post({
    url: `${getEnv("ITROOTURL")}/request-status/${
      data.requestId
    }/approval-status-update`,
    data,
  });
};

export const updateApproverActionLog = async ({ data }) => {
  return await post({
    url: `${getEnv("ITROOTURL")}/audit-log/approver-action`,
    data,
  });
};

export const updateRequestNotes = async ({ data }) => {
  return await post({
    url: `${getEnv("ITROOTURL")}/note-management/requests/${
      data.requestId
    }/notes`,
    data,
  });
};

export const updateBusinessImpact = async ({ approvalID, data, headers }) => {
  return await patch({
    url: `${getEnv(
      "ITROOTURL"
    )}/request-management/requests/${approvalID}/business-impact`,
    data,
    headers,
  });
};

export const getBusinessImpactEnums = async () => {
  return await get({
    url: `${getEnv("ITROOTURL")}/request-management/business-impact-enums`,
  });
};

export const updateErrorLog = async (data) => {
  return await post({
    url: `${getEnv("ITROOTURL")}/audit-log/log-error`,
    data,
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  });
};

export const getMe = async () => {
  return (
    await get({
      url: `${getEnv("ITROOTURL")}/user/getTeamMemberTeamData/${user.TeamId}`,
    })
  ).data;
};

export const getTeamMember = async ({ id } = {}) => {
  return (
    await get({
      url: `${getEnv("ITROOTURL")}/user/getTeamMemberTeamData/${id}`,
    })
  ).data;
};

export const submitPendingActionForm = async ({ data, requestId }) => {
  return await post({
    url: `${getEnv(
      "ITROOTURL"
    )}/myapplied/requests/${requestId}/approval-action-update`,
    data,
  });
};

export const generateApprovalsList = async (
  teamMemberNumber,
  approvalLevel
) => {
  return (
    await get({
      url: `${getEnv(
        "ITROOTURL"
      )}/User/GenerateApprovalsList/${teamMemberNumber}?approvalLevel=${approvalLevel}`,
    })
  ).data;
};

export const editApprovalChain = async (data) => {
  return (
    await post({
      url: `${getEnv(
        "DECISIONS2_ENDPOINT"
      )}/?FlowAlias=ReorderRemoveAddEmailApproversARC&action=api`,
      params: { SessionId: JwtToken },
      data,
    })
  ).data;
};

export const updateApproverChangeLogs = async (data) => {
  return (
    await post({
      url: `${getEnv("ITROOTURL")}/audit-log/approver-change`,
      data,
    })
  ).data;
};

export const updateRequest = async ({ id, data, headers }) => {
  return (
    await patch({
      url: `${getEnv("ITROOTURL")}/request-management/requests/${id}`,
      data,
      headers,
    })
  ).data;
};

export const searchUsers = (input) => {
  return get({
    url: `${getEnv("BASEURL")}/directory/${user.TeamId}/${input.trim()}`,
  });
};

export const editApprovers = async ({ data, requestId }) => {
  return await post({
    url: `${getEnv(
      "ITROOTURL"
    )}/myapplied/requests/${requestId}/update-approver-chain`,
    data,
  });
};

export const getFilesAuth = async () => {
  return (
    await get({
      url: `${getEnv("ITROOTURL")}/user/get-files-auth`,
    })
  ).data;
};

export const getFiles = async ({ path, token }) => {
  const response = await axios.get(
    `https://graph.microsoft.com/v1.0/drives/${getEnv(
      "SHAREPOINT_DRIVE_ID"
    )}/root:/${path}:/children`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
