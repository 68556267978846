import { set } from "lodash";
import React, { createContext, useContext, useState } from "react";

const SystemOutageContext = createContext();

export const useSystemOutage = () => {
  return useContext(SystemOutageContext);
};

export const SystemOutageProvider = ({ children }) => {
  const [displayBanner, setDisplayBanner] = useState(false); // Toggle to display banner
  const [bannerHeight, setBannerHeight] = useState(0);
  const [isNewsroomOpen, setIsNewsroomOpen] = useState(false);
  const [currentAppDown, setCurrentAppDown] = useState("default");

  // Configure the base paths for the different applications
  // values are arrays in case a web app on myapplied has multiple base paths
  // or to specify multiple default paths in case of a system outage
  const basePaths = {
    ecommerce: ["/applied-shop"],
    certInsurance: ["/resources/Certificates%20of%20Insurance%20and%20Workers%20Compensation"],
    default: ["**N/A**"],
  };

  // Configure the pages where the banner should be displayed (if no pages are specified, set pagesToDisplayBanner to an empty array [])
  const pagesToDisplayBanner = [...basePaths[currentAppDown]];

  const outageMessages = {
    ecommerce:
      "We are having connection issues with our payment provider. You may continue to add items to the cart but will need to come back later to checkout. We appreciate your patience.",
    certInsurance:
      "Please refresh the page if Certificates of Insurance is not loading. The team is currently working on a fix.",
    default:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque porro, voluptates corporis minima sint illo deserunt quia quisquam hic eius qui magni voluptatibus nostrum placeat eum nam quo autem distinctio suscipit. Reiciendis dolorum, earum quidem eius beatae dignissimos ea fuga perspiciatis aspernatur libero obcaecati voluptatibus odit quasi nulla eaque nostrum. Sapiente, tenetur omnis doloribus illo voluptates sed accusamus inventore odio explicabo impedit dolor rem vel atque veniam ut doloremque molestiae, ipsam optio. Nam minima recusandae ut voluptas illo consequatur, laudantium tempore rem reiciendis eum nesciunt assumenda rerum nulla unde ab cupiditate ipsum adipisci dolorum amet non distinctio culpa autem! Maiores.",
  };

  const outageTitles = {
    ecommerce: "Current Payment Connection Problems",
    default: "Service Outage",
  };

  // Configure the outage information
  const outageInfo = {
    title: outageTitles[currentAppDown],
    message: outageMessages[currentAppDown],
  };

  return (
    <SystemOutageContext.Provider
      value={{
        displayBanner,
        setDisplayBanner,
        bannerHeight,
        setBannerHeight,
        isNewsroomOpen,
        setIsNewsroomOpen,
        pagesToDisplayBanner,
        outageInfo,
        currentAppDown,
        setCurrentAppDown,
      }}
    >
      {children}
    </SystemOutageContext.Provider>
  );
};
